//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import svgSymbol from '~/components/svg-symbol'


export default {

	components: { svgSymbol },

	props:
	{
		item: { type: Object, required: true }
	},

	methods:
	{
		clickAnalytics(item)
		{
			item.analytics_event && this.$gtag && this.$gtag.event( 'click', { event_category:'Link', event_label:  item.analytics_event, value:  1 });
			this.$fb && this.$fb.track('track', 'Contact');
		},

		isSocial(item)
		{
			if (!item.has_icon)	return false;

			switch (item.analytics_event)
			{
				case "Facebook":
				case "Twitter":
				case "LinkedIn":
				case "Instagram":
				case "Youtube":
					return item.analytics_event;

				default: return false;
			}

			return false;
		}
	}


}
