//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import RestString from '~/components/rest-string-dummy'
import RestStringLink from '~/components/rest-string-link-dummy'
// import RestLinkBtn from '~/components/rest-link-btn-dummy'
import RestImage from '~/components/rest-img-dummy'
import RestProp from '~/components/rest-prop-dummy'
import InputFocus from '~/components/input-focus'
import CtaArrow from '~/components/cta-arrow'
import svgSymbol from '~/components/svg-symbol'
import Intersect from '~/components/vue-intersect'
import ScrollUp from '~/components/scroll-up'
import FooterItem from '~/components/footer-item'

export default {
	name: 'site-footer',

	components:
	{
		RestString,
		RestStringLink,
		// RestLinkBtn,
		RestImage,
		RestProp,
		InputFocus,
		CtaArrow,
		svgSymbol,
		Intersect,
		ScrollUp,
		FooterItem,
	},

	data()
	{
		return {
			emailSending: false,
			emailSuccess: null,
			emailError: null,
		}
	},

	watch:
	{
		emailSuccess(newVal)
		{
			if ( !!newVal )
			{
				setTimeout(()=>
				{
					this.emailSuccess = null;
				},
				4*1000);
			}
		},

		// footerData(newVal)
		// {
		// 	consola.debug('footerData: ', newVal)
		// }
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'getStringId', 'siteData', 'footerData', 'lang', 'langShort', 'allLanguages', 'isDesktop', 'isMobile', 'isTablet']),

		upperLabel()
		{
			return this.upperList.find(x => x.type="label");
		},

		upperList()
		{
			// debugger;
			if (!this.footerData)	return [];
			let section = this.footerData.filter(x => x.name=="upper" ) || null;

			return section && section[0] && section[0].list || [];
		},

		upperFooterItems()
		{
			return this.upperList.filter(x => x.type=='footer-item');
		},

		lowerList()
		{
			if (!this.footerData)	return [];
			let section = this.footerData.filter(x => x.name=="lower" ) || null;

			return section && section[0] && section[0].list || [];
		},

		formClasses()
		{
			return {
				'Footer__form--sending': this.emailSending,
				'Footer__form--success': !!this.emailSuccess,
				'Footer__form--error': !!this.emailError,
			}
		}
	},

	methods:
	{
		clickAnalytics(item)
		{
			item.analytics_event && this.$gtag && this.$gtag.event( 'click', { event_category:'Link', event_label:  item.analytics_event, value:  1 });
			this.$fb && this.$fb.track('track', 'Contact');
		},

		onSubscribe(event)
		{
			var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

			const userEmail = this.$refs.emailField.$el.value;

			event.preventDefault();
			if (emailRegex.test(userEmail))
			{
				this.emailSending = true;
				this.$apiMail({ email: userEmail })
				.then( resp =>
				{
					// debugger;
					consola.debug('mail subscribe: ', resp);
					this.emailError = null;
					this.emailSuccess = true;
					this.emailSending = false;
					this.$gtag && this.$gtag.event( 'submit', { event_category:'Newsletter Email', event_label:  'success', value:  1 });
					this.$fb && this.$fb.track('track', 'CompleteRegistration');
				})
				.catch( err =>
				{
					// debugger;
					consola.debug('mail subscribe error: ', err);
					this.emailError = err.response.data.error.message || "error";
					this.emailSuccess = null;
					this.emailSending = false;
					this.$gtag && this.$gtag.event( 'submit', { event_category:'Newsletter Email', event_label:  'failure', value:  1 });

				})
			}
			else
			{
				// act on error
				consola.debug(`mail error: "${userEmail}" is not a valid email address`)
				this.emailError = "not a valid email address";
				this.emailSuccess = null;
				this.emailSending = false;
			}
		},

		showForm(item)
		{
			this.clickAnalytics(item)
			this.$root.$emit( 'show-external-form', item.url )
		},

		onShowContactForm(event)
		{
			this.$root.$emit( 'show-contact-form' )
		},
	}

}
