import { render, staticRenderFns } from "./footer-admin.vue?vue&type=template&id=71cd2950&lang=pug&"
import script from "./footer-admin.vue?vue&type=script&lang=js&"
export * from "./footer-admin.vue?vue&type=script&lang=js&"
import style0 from "./footer-admin.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterItem: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/footer-item.vue').default,RestString: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-string.vue').default,InputFocus: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/input-focus.vue').default,SvgSymbol: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/svg-symbol.vue').default,ScrollUp: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/scroll-up.vue').default,Footer: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/footer.vue').default})
