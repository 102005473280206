//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import svgSymbol from '~/components/svg-symbol'

export default {

	components:
	{
		svgSymbol
	},

	props:
	{
		value:
		{
			type: Boolean,
			default: false,
		},

		// localeUrls:
		// {
		// 	type: Object,
		// 	required: true,
		// },

		src:
		{
			type: String,
			required: true,
		},

		analyticsLabel:
		{
			type: String,
			default: 'form',
		}
	},

	data()
	{
		return {
			openingForm: false,
			showForm: false,
		}
	},

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop']),

		// src()
		// {
		// 	return this.localeUrls[this.lang] || '';
		// },
	},

	watch:
	{
		value(newVal)
		{
			this.showForm = newVal;
		},

		showForm(newVal)
		{
			this.$emit('input', newVal);
		}
	},

	created()
	{
		this.showForm = this.value;
	},

	mounted()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(true)
		}
	},

	beforeDestroy()
	{
		if (process.client)
		{
			this.$freezeBodyScroll(false)
		}
	},

	methods:
	{

		onShowForm()
		{
			consola.debug('onShowForm')
			// debugger;
			if (this.openingForm)
			{
				consola.debug('already opening the form...')
				return;
			}

			this.$gtag && this.$gtag.event( 'click', { event_category:this.analyticsLabel, event_label:  'open form', value:  1 });

			this.openingForm = true;
			// if (this.$refs['form'] && this.$refs['form'].querySelector('.conversational-form'))
			if (this.$refs['form'] && this.$refs['form'].querySelector('iframe'))
			{
				this.showForm = true;
				this.openingForm = false;
			}
			else
			{
				this.setupForm()
			}
		},

		closeForm()
		{
			if (!this.showForm)	return;
			this.showForm = false;
			this.openingForm = false;
			this.$gtag && this.$gtag.event( 'click', { event_category:this.analyticsLabel, event_label:  'close form', value:  1 });
		},

		destroyForm()
		{
			// if ( this.$refs['form'] )
			// {
			// 	setTimeout(
			// 		() => { this.$refs['form'].innerHTML = ''; }
			// 		, 300
			// 	)
			// }
		},

		setupForm()
		{
			// this.$refs['form'].innerHTML = forms[formType][this.lang] || '';
			// if ( !this.$refs['form'].innerHTML )
			// 	return;
			this.openingForm = true;
			this.showForm = true;


			// setTimeout(
			// 	()=>
			// 	{
			// 		this.$refs['form'].children[0].onload = () =>
			// 		{
			// 			this.showForm = true;
			// 			this.openingForm = false;
			// 		};
			// 	},
			// 	10
			// );

		},
	}
}
