import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=463b6902&lang=pug&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterItem: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/footer-item.vue').default,RestString: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-string.vue').default,InputFocus: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/input-focus.vue').default,SvgSymbol: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/svg-symbol.vue').default,ScrollUp: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/scroll-up.vue').default,Footer: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/footer.vue').default})
