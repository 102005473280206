//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Promise } from 'q';

export default {
	data()
	{
		return {
			modelStore: {},
			stringStore: {},
			imgStore: [],
			isSaving: false,

			modelResponse: {}
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'lang', 'langShort', 'allLanguages']),
	},

	mounted()
	{
		this.$eventBus.$on('rest:model', (model) =>
		{
			this.modelStore[model.singletonCounter] = model
			// if (this.modelList.indexOf(model) == -1)
			// 	this.modelList.push = model
		})

		this.$eventBus.$on('rest:string', (str) =>
		{
			this.stringStore[str.singletonCounter] = str

			// if (this.stringList.indexOf(str) == -1)
			// 	this.stringList.push = str
		})

		this.$eventBus.$on('rest:image:upload:begin', (img) =>
		{
			this.imgStore.push( img )
		});
	},

	methods:
	{
		...mapMutations(['setEditingPage', 'setString']),

		cancelEditing(e)
		{
			e.preventDefault()
			e.stopPropagation()

			let keys = Object.keys(this.modelStore);

			for (let i = 0; i < keys.length; i++)
			{
				const item = this.modelStore[keys[i]]
				if (!item)	continue

				item.cancelCommit()
			}

			keys = Object.keys(this.stringStore);
			let stringList = [];
			for (let i = 0; i < keys.length; i++)
			{
				const item = this.stringStore[keys[i]]
				if (!item)	continue

				stringList.push( item )
			}
			if (stringList.length)
			{
				stringList[0].clearAll( stringList )
			}

			for (let i = 0; i < this.imgStore.length; i++)
			{
				this.imgStore[i].clear();
			}

			this.modelStore = {};
			this.stringStore = {};
			this.imgStore = [];

			this.isSaving = false;
			this.setEditingPage( { editing: !this.isEditingPage } )
			consola.debug('...canceling editing')
		},

		toggleEditing()
		{
			consola.debug('toggleEditing')

			if (this.isEditingPage)
			{
				this.$root.$loading.start()
				consola.debug('saving...')
				// save all the data
				this.isSaving = true;

				let promises = [];

				let keys = Object.keys(this.modelStore);

				for (let i = 0; i < keys.length; i++)
				{
					const item = this.modelStore[keys[i]]
					if (!item)	continue

					promises.push( item.asyncCommit() )
				}

				keys = Object.keys(this.stringStore);
				let stringList = [];
				for (let i = 0; i < keys.length; i++)
				{
					const item = this.stringStore[keys[i]]
					if (!item)	continue

					stringList.push( item )
				}

				if (stringList.length)
				{
					promises.push( stringList[0].asyncSaveAll( stringList ) )
				}

				if (promises.length)
				{
					consola.debug('promises: ', promises)
					Promise.all(promises)
						.then( (values) =>
						{
							consola.debug('Promise.all().then(): ', values)
							keys = Object.keys(this.modelStore);

							this.modelResponse = {};

							for (let i = 0; i < keys.length; i++)
							{
								const item = this.modelStore[keys[i]]
								if (!item)	continue

								this.modelResponse[item.url] = this.modelResponse[item.url] || Object.freeze([]);

								let data = values.shift();
								if (data)	data = data.data;

								if (data)
								{
									if ( Array.isArray(data) )
									{
										this.modelResponse[item.url] = Object.freeze( [ ...this.modelResponse[item.url], ...data ] )
									}
									else
									{
										this.modelResponse[item.url] = Object.freeze( [ ...this.modelResponse[item.url], data ] )
									}
								}
								// const val = values.shift().data
							}

							if ( Object.keys(this.modelResponse).length )
							{
								this.$eventBus.$emit( 'rest:response', this.modelResponse )
							}

							while (values.length)
							{
								let item = values.shift()
								if (!item)	continue;

								this.setString( item.data )
							}

							// keys = Object.keys(this.stringStore);
							// let stringList = [];
							// for (let i = 0; i < keys.length; i++)
							// {
							// 	const item = this.stringStore[keys[i]]
							// 	if (!item)	continue

							// 	stringList.push( item )
							// }
						})
						.catch( (error)=>
						{
							consola.debug('Promise.all().catch()')

							console.error(error)
						})
						.finally( ()=>
						{
							consola.debug('Promise.all().finally()')

							this.modelStore = {};
							this.stringStore = {};
							this.imgStore = [];

							// if (stringList.length)
							// 	stringList[0].clearAll( stringList )

							this.isSaving = false;
							this.setEditingPage( { editing: !this.isEditingPage } )
							consola.debug('...finished saving')
							this.$root.$loading.finish()
						})
				}
				else
				{
					this.modelStore = {};
					this.stringStore = {};

					this.isSaving = false;
					this.setEditingPage( { editing: !this.isEditingPage } )
					consola.debug('...finished saving [no-data]')
				}
			}
			else
				this.setEditingPage( { editing: !this.isEditingPage } )

			this.$root.$loading.finish()
		},
	}
}
